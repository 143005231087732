import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import UserTermsTable from './UserTermsTable';
import UserProfileSettings from './Settings';
import Reservations from '../reservations/Reservations';
import CreateTerm from '../admin/CreateTerm';
import PatientsTable from '../admin/PatientsTable';
import TermTypesTable from '../admin/TermTypesTable';
import configApi from '../../config';
import UsersTable from '../admin/UsersTable';
import MainStatsComponent from '../Stats/MainStats';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../shared/LoadingSpinner';
import { useSidebar } from './../SidebarContext';
import Summary from './Summary';

const MyProfile = () => {
  const { isSidebarVisible, toggleSidebar } = useSidebar();
  const [user, setUser] = useState(null);
  const [selected, setSelected] = useState('settings');
  const [navigationItems, setNavigationItems] = useState([]);
  const [userTerms, setUserTerms] = useState([]);
  const navigate = useNavigate();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      try {
        const response = await axios.get(`${configApi.apiEndpoint}/api/account/profile`, config);
        setUser(response.data);

      } catch (error) {
        console.error('Failed to fetch profile:', error);
        navigate("/login");
      }
    };

    fetchProfile();
  }, [navigate]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isSidebarVisible) {
        toggleSidebar();
      }
    };

    // Only add the event listener if the sidebar is visible
    if (isSidebarVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    // Cleanup function to remove the event listener
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isSidebarVisible, toggleSidebar]);

  useEffect(() => {
    const items = [
      { name: 'reservations', label: 'Rezervácie', component: Reservations },
      { name: 'users', label: 'Moje termíny', component: () => <UserTermsTable userId={user.id} /> },
      { name: 'settings', label: 'Nastavenia', component: UserProfileSettings },
    ];

    if (user?.roles?.includes('Admin')) {
      items.push({ name: 'summary', label: 'Sumarizacia', component: () => <Summary /> });
      items.push({ name: 'patientsTable', label: 'Pacienti', component: PatientsTable });
      items.push({ name: 'createterm', label: 'Termíny', component: CreateTerm });
      items.push({ name: 'usersTable', label: 'Používatelia', component: UsersTable });
      items.push({ name: 'termtypestable', label: 'Typy termínov', component: TermTypesTable });
      items.push({ name: 'mainStatsComponent', label: 'Štatistiky', component: MainStatsComponent });
    }

    setNavigationItems(items);
  }, [user]);

  if (!user) {
    return (
      <LoadingSpinner />
    )
  }

  const handleClick = (name) => {
    setSelected(name); // Your existing logic to handle click
    toggleSidebar();   // Additionally, toggle the sidebar to close it
  };

  const SelectedComponent = navigationItems.find(item => item.name === selected)?.component;

  return (
    <div className='profile'>
      <div ref={sidebarRef} className={`sidebar ${isSidebarVisible ? '' : 'hidden'}`}>
        {navigationItems.map((item) => (
          <Link
            key={item.name}
            to="#"
            name={item.name}
            onClick={() => handleClick(item.name)}
            className={
              selected === item.name ? 'active' : ''
            }
          >
            {item.label}
          </Link>
        ))}
      </div>
      <div id="divToRenderProfileComponents" className='content-wrapper'>
        {SelectedComponent && <SelectedComponent user={user} />}
      </div>
    </div >
  );
};

export default MyProfile;
