// TermsOverTimeChart.js
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import configApi from '../../config';

const TermsOverTimeChart = () => {
    const chartRef = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const [startDate, setStartDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const token = localStorage.getItem("token");
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const fetchTerms = async () => {
        if (!startDate || !endDate) {
            console.warn('Začiatočný alebo koncový dátum nie je nastavený.');
            return;
        }

        if (startDate > endDate) {
            alert('Začiatočný dátum nemôže byť po koncovom dátume.');
            return;
        }

        try {
            const response = await axios.get(
                `${configApi.apiEndpoint}/api/Terms/GetTermsByDateRange`,
                {
                    ...config,
                    params: {
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                    },
                }
            );
            const terms = response.data;
            processTerms(terms);
        } catch (error) {
            console.error('Chyba pri načítavaní termínov:', error);
        }
    };

    const processTerms = (terms) => {
        const termsByDate = {};
        let minDate = null;
        let maxDate = null;
        terms.forEach(term => {
            const dateObj = new Date(term.date);
            const date = dateObj.setHours(0, 0, 0, 0); // Normalize to midnight
            termsByDate[date] = (termsByDate[date] || 0) + 1;
            if (!minDate || date < minDate) minDate = date;
            if (!maxDate || date > maxDate) maxDate = date;
        });
        createChart(termsByDate, minDate, maxDate);
    };

    const createChart = (termsByDate, minDate, maxDate) => {
        const dataPoints = Object.keys(termsByDate).sort().map(timestamp => ({
            x: new Date(parseInt(timestamp)),
            y: termsByDate[timestamp]
        }));

        const chartConfig = {
            type: 'line',
            data: {
                datasets: [{
                    label: 'Počet termínov',
                    data: dataPoints,
                    fill: false,
                    borderColor: '#4BC0C0',
                    tension: 0.1
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'day',
                            displayFormats: {
                                day: 'dd.MM.yyyy'
                            }
                        },
                        title: {
                            display: true,
                            text: 'Dátum'
                        },
                        min: minDate ? new Date(minDate) : undefined,
                        max: maxDate ? new Date(maxDate) : undefined,
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Počet termínov'
                        }
                    }
                },
                plugins: {
                    title: {
                        display: true,
                        text: `Termíny v čase (${new Date(minDate).toLocaleDateString()} - ${new Date(maxDate).toLocaleDateString()})`
                    },
                    tooltip: {
                        callbacks: {
                            title: function (context) {
                                const date = context[0].parsed.x;
                                return new Date(date).toLocaleDateString();
                            }
                        }
                    }
                }
            }
        };

        if (chartInstance) {
            chartInstance.data = chartConfig.data;
            chartInstance.options = chartConfig.options;
            chartInstance.update();
        } else {
            const newChartInstance = new Chart(chartRef.current.getContext('2d'), chartConfig);
            setChartInstance(newChartInstance);
        }
    };

    useEffect(() => {
        fetchTerms();
    }, []);

    return (
        <div className="card-custom">
            <div className="card-custom-header">
                <h5>Termíny v čase</h5>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <div>
                    <label>Začiatočný dátum: </label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd.MM.yyyy" />
                </div>
                <div style={{ marginLeft: '20px' }}>
                    <label>Koncový dátum: </label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="dd.MM.yyyy" />
                </div>
                <button className="button-primary" onClick={fetchTerms} style={{ marginLeft: '20px' }}>Aktualizovať graf</button>
            </div>
            <div style={{ height: '400px' }}>
                <canvas ref={chartRef}></canvas>
            </div>
        </div>
    );
};

export default TermsOverTimeChart;
