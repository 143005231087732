import React, { useEffect, useState } from "react";
import axios from "axios";
import configApi from "../../config";

const PatientStats = () => {
  const [patients, setPatients] = useState([]);
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const response = await axios.get(
        `${configApi.apiEndpoint}/api/Patients`,
        config
      );
      setPatients(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="stats-card">
      <h2>{patients.length}</h2>
      <span>Počet pacientov</span>
    </div>
  );
};

export default PatientStats;
