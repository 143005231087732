// Summary.js
import React from 'react';
import UserTermsTable from './UserTermsTable';
import PatientStats from '../Stats/PatientStats';
import Reservations from '../reservations/Reservations';
import TotalReservations from '../Stats/TotalReservations';
import ReservationsByMonth from '../Stats/ResByMonth';
import PopularTermTypes from '../Stats/PopularTermTypes';
import NewestTerms from '../reservations/NewestTerms';

const Summary = () => {
    return (
        <div className="d-flex flex-column gap-3 home-container">
            <div className="row g-3">
                <div className="col-md-8 col-12">
                    <NewestTerms />
                </div>
                <div className="col-md-4 col-12">
                    <div className="d-flex flex-column gap-3">
                        <div className="row g-3">
                            <div className="col-lg-6">
                                <TotalReservations />
                            </div>
                            <div className="col-lg-6">
                                <PatientStats />
                            </div>
                        </div>
                        <PopularTermTypes />
                    </div>
                </div>
            </div>
            <div className="row g-3">
                <div className="col-md-8 col-sm-12">
                    <UserTermsTable />
                </div>
                <div className="col-md-4 col-sm-12">
                    <ReservationsByMonth />
                </div>
            </div>
        </div>
    );
};

export default Summary;
