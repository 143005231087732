// TermTypePieChart.js
import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import configApi from '../../config';

const TermTypePieChart = () => {
    const chartRef = useRef(null);
    const token = localStorage.getItem("token");
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${configApi.apiEndpoint}/api/Stats/PopularTermTypes`,
                    config
                );
                const data = response.data;
                createChart(data);
            } catch (error) {
                console.error('Chyba pri načítavaní údajov o typoch termínov:', error);
            }
        };

        const createChart = (data) => {
            const ctx = chartRef.current.getContext('2d');
            const labels = data.map(item => item.termTypeName || 'Nezaradené');
            const counts = data.map(item => item.count);
            new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            data: counts,
                            backgroundColor: [
                                '#FF6384',
                                '#36A2EB',
                                '#FFCE56',
                                '#4BC0C0',
                                '#9966FF',
                            ],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Populárne typy termínov'
                        },
                    },
                },
            });
        };

        fetchData();
    }, []);

    return (
        <div className="card-custom">
            <div className="card-custom-header">
                <h5>Populárne typy termínov</h5>
            </div>
            <div style={{ height: '300px' }}>
                <canvas ref={chartRef}></canvas>
            </div>
        </div>
    );
};

export default TermTypePieChart;
