import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';

const ResetPassword = ({ user }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const data = {
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword
      };

      console.log(data);

      const response = await axios.post(
        `${config.apiEndpoint}/api/account/resetpassword`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setSuccessMessage(response.data.message);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');

      setTimeout(() => {
        setSuccessMessage(response.data.message);
      }, 3000);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  return (
    <div className='card-custom'>
      <h2>Reset hesla</h2>
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="currentPassword" className="form-label">
            Aktuálne heslo
          </label>
          <input
            type="password"
            className="form-control"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            placeholder="Aktuálne heslo"
            autoComplete="off"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="newPassword" className="form-label">
            Nové heslo
          </label>
          <input
            type="password"
            className="form-control"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Nové heslo"
            autoComplete="off"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Potvrdenie nového hesla
          </label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Potvrdenie nového hesla"
            autoComplete="off"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Resetovať heslo
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
