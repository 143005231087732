import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configApi from '../../config';

const TotalReservations = () => {
    const [total, setTotal] = useState(0);
    const token = localStorage.getItem("token");
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    useEffect(() => {
        axios.get(`${configApi.apiEndpoint}/api/Stats/TotalReservations`, config)
            .then(response => setTotal(response.data))
            .catch(error => console.error('There was an error fetching the total reservations:', error));
    }, []);

    return (
        <div className="stats-card">
            <h2>{total}</h2>
            <span>Všetky rezervácie</span>
        </div>
    );
};

export default TotalReservations;
