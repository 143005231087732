import React, { Component } from "react";
import LoadingSpinner from "./shared/LoadingSpinner";

export class TermList extends Component {
  static displayName = TermList.name;

  constructor(props) {
    super(props);
    this.state = {
      hoveredTermId: null,
    };
  }

  handleTermClick = (termId) => {
    this.props.onTermClick(termId);
  };

  handleTermHover = (termId) => {
    this.setState({ hoveredTermId: termId });
  };

  handleTermLeave = () => {
    this.setState({ hoveredTermId: null });
  };

  render() {
    const { date, terms, termTypes, selectedTermId, isAdmin, isLoading } = this.props;
    const { hoveredTermId } = this.state;

    let shortestDuration = termTypes.reduce((min, type) => type.duration < min ? type.duration : min, Infinity);
    if (isAdmin) {
      shortestDuration = 0;
    }
    const filteredTerms = terms ? terms.filter(term => term.duration >= shortestDuration) : [];
    console.log(this);
    return (
      <div className="">
        <div className="d-flex">
          <div className="date-details">
            <span className="day-number">{date.getDate()}</span>
            <span className="month-name">{date.toLocaleDateString("sk-SK", { month: 'short' })}</span>
          </div>
          <div className="date-name">
            <span className="nameday">{date.toLocaleDateString("sk-SK", { weekday: 'long' })}</span>
            <span className="stats">{filteredTerms.length} voľných termínov</span>
          </div>
        </div>
        {isLoading ? <LoadingSpinner /> : ""}
        {filteredTerms.length === 0 ? (<span className="p-2">Na tento deň nie su žiadne voľné termíny.</span>) : null}

        <ul className="term-list">
          {filteredTerms.map((term) => {
            const formattedTime = new Date(term.date).toLocaleTimeString(
              "sk-SK",
              { hour: "2-digit", minute: "2-digit" }
            );
            const isHovered = hoveredTermId === term.id;
            const durationInMinutes = term.duration; // assuming term.duration is in minutes
            const termDate = new Date(term.date);
            const endTime = new Date(termDate.getTime() + durationInMinutes * 60000); // 60000 milliseconds in a minute

            const formattedTimeEnd = endTime.toLocaleTimeString(
              "sk-SK",
              { hour: "2-digit", minute: "2-digit" }
            );

            return (
              <a
                className={`term`}
                key={term.id}
                onClick={() => this.handleTermClick(term.id)}
                onMouseEnter={() => this.handleTermHover(term.id)}
                onMouseLeave={this.handleTermLeave}
                style={{ cursor: "pointer" }}
              >
                <span className="time">{formattedTime}</span>
                {term.termType ? (
                  <div className={`term-inside ${term.isOccupied
                    ? "term-occupied"
                    : ""
                    }  ${selectedTermId === term.id ? "active" : ""} ${isHovered ? "bg-opacity-75" : ""
                    }`}>
                    <span className="term-small">{formattedTime} - {formattedTimeEnd} ({term.duration}min)</span>
                    <span className={`term-main`}>{term.termType?.name} | {term.termType?.duration} min. {term.patient ? " | " + term.patient.firstName + " " + term.patient.lastName : null}</span>

                  </div>
                ) : (
                  <div className={`term-inside ${term.isHidden ? "term-hidden" : ""}`}>
                    <span className="term-small">{formattedTime} - {formattedTimeEnd} ({term.duration}min)</span>
                    <span className="term-main">{term.isHidden ? "PAUZA" : "Voľný termín"}</span>
                  </div>
                )}
              </a>
            );
          })}
        </ul>
      </div>
    );
  }
}
