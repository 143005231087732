import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already logged in
    const token = localStorage.getItem("token");
    if (token) {
      // User is already logged in, redirect to the desired page
      navigate("/user");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.apiEndpoint}/api/account/login`,
        {
          // Trim the inputs to remove any white spaces
          username: username.trim(),
          password: password.trim(),
        }
      );
      const expirationTime = new Date().getTime() + 3 * 60 * 60 * 1000; // 3 hours from now
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", response.data.username.trim()); // Save the trimmed username
      localStorage.setItem("tokenExpiration", expirationTime);
      navigate("/user"); // Redirect to the desired page after successful login
      window.location.reload();
    } catch (error) {
      setError("Prihlásenie zlyhalo. Skontrolujte svoje prihlasovacie údaje a skúste to znova.");
    }
  };

  return (
    <div className="container py-3">
      <div className="row justify-content-center">
        <div className="col-md-6 col-xl-3">
          <h1 className="text-left">Prihlásenie</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Prihlasovacie meno
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Prihlasovacie meno"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Heslo
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Heslo"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Prihlásiť sa
            </button>
          </form>
          {error && (
            <div className="alert alert-danger mt-3" role="alert">
              {error}
            </div>
          )}
          <p className="mt-3 text-left">
            Nemáš účet? <a href="/register">Registruj sa.</a>
          </p>
          <p className="mt-3 text-left">
            Zabudnuté heslo? <a href="/forgot-password">Resetuj heslo.</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
