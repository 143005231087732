import React, { useState } from "react";
import axios from "axios";
import { DateTime } from "luxon";
import config from "../../config";

const CreateTermAutomat = () => {
  // Initialize start and end date with default values
  const currentDateTime = DateTime.now();
  const nextDate = currentDateTime.plus({ days: 1 });
  const defaultStartTime = nextDate.set({ hour: 8, minute: 0 });
  const defaultEndTime = nextDate.set({ hour: 17, minute: 0 });

  const [startDate, setStartDate] = useState(defaultStartTime.toFormat("yyyy-MM-dd'T'HH:mm"));
  const [endDate, setEndDate] = useState(defaultEndTime.toFormat("yyyy-MM-dd'T'HH:mm"));
  const [durationStep, setDurationStep] = useState(60);
  const [termsToBeGenerated, setTermsToBeGenerated] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);

    if (start > end) {
      setErrorMessage("End date/time should be greater than Start date/time");
      return;
    }

    try {
      const durationInMinutes = parseInt(durationStep);
      const numOfTerms = Math.floor(end.diff(start, 'minutes').minutes / durationInMinutes);

      const terms = [];
      let currentDateTime = start;

      for (let i = 0; i < numOfTerms; i++) {
        terms.push(currentDateTime);
        currentDateTime = currentDateTime.plus({ minutes: durationInMinutes });
        if (currentDateTime > end) {
          break;
        }
      }

      setTermsToBeGenerated(terms);
      setSuccessMessage(`Počet termínov, ktoré sa vytvoria: ${terms.length}`);
    } catch (error) {
      setErrorMessage(
        "Nepodarilo sa, pravdepodobne sú v týchto časoch definované iné termíny."
      );
      console.error("Failed to create terms:", error);
    }
  };

  const handleConfirmation = async () => {
    try {
      const token = localStorage.getItem("token");
      const createTermPromises = termsToBeGenerated.map((term) => {
        const data = {
          date: term.toUTC().toISO(),
          isOccupied: false,
          duration: durationStep,
        };

        return axios.post(`${config.apiEndpoint}/api/Terms`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
      });

      await Promise.all(createTermPromises);

      setTermsToBeGenerated([]);
      setSuccessMessage("Termíny úspešne vytvorené!");
    } catch (error) {
      setErrorMessage(
        "Nepodarilo sa, pravdepodobne sú v týchto časoch definované iné termíny."
      );
      console.error("Failed to create terms:", error);
    }
  };

  const handleConfirmationDismiss = () => {
    setTermsToBeGenerated([]);
    setSuccessMessage("");
  };

  const handleStartDateChange = (e) => {
    const localDate = DateTime.fromISO(e.target.value);
    const now = DateTime.now();

    if (localDate < now) {
      setStartDate(now.toFormat("yyyy-MM-dd'T'HH:mm"));
    } else {
      setStartDate(localDate.toFormat("yyyy-MM-dd'T'HH:mm"));
    }

    const newEndDate = localDate.plus({ minutes: 480 });
    if (newEndDate > DateTime.fromISO(endDate)) {
      setEndDate(newEndDate.toFormat("yyyy-MM-dd'T'HH:mm"));
    }
  };

  const handleEndDateChange = (e) => {
    const localDate = DateTime.fromISO(e.target.value);
    const startDateObj = DateTime.fromISO(startDate);

    if (localDate <= startDateObj) {
      const newEndDate = startDateObj.plus({ minutes: 60 });
      setEndDate(newEndDate.toFormat("yyyy-MM-dd'T'HH:mm"));
    } else {
      setEndDate(localDate.toFormat("yyyy-MM-dd'T'HH:mm"));
    }
  };

  return (
    <div>
      <h2>Vytvoriť termíny (Automaticky)</h2>
      {successMessage && (
        <div className="alert alert-success alert-dismissible" role="alert">
          {successMessage}
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleConfirmationDismiss}
          ></button>
        </div>
      )}
      {termsToBeGenerated.length > 0 && (
        <div className="alert alert-info" role="alert">
          Termíny, ktoré sa majú vytvoriť:
          <ul>
            {termsToBeGenerated.map((term, index) => (
              <li key={index}>
                <b>
                  {term.toFormat("HH:mm")}{" "}
                </b>
                {term.toFormat("dd.MM.yyyy (cccc)")}{" ("}
                {durationStep}{" min)"}
              </li>
            ))}
          </ul>
          <button className="btn btn-primary" onClick={handleConfirmation}>
            Potvrdiť vytvorenie termínov
          </button>
        </div>
      )}

      {errorMessage && (
        <div className="alert alert-danger alert-dismissible" role="alert">
          {errorMessage}
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setErrorMessage("")}
          ></button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="row g-3">
        <div className="col-md-6">
          <label htmlFor="startDate" className="form-label">
            Začiatok termínov
          </label>
          <input
            type="datetime-local"
            className="form-control"
            id="startDate"
            value={startDate}
            min={DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm")}
            onChange={handleStartDateChange}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="endDate" className="form-label">
            Koniec termínov
          </label>
          <input
            type="datetime-local"
            className="form-control"
            id="endDate"
            value={endDate}
            min={startDate}
            onChange={handleEndDateChange}
            required
            disabled={DateTime.fromISO(startDate) > DateTime.fromISO(endDate)}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="durationStep" className="form-label">
            Vytvoriť termín každých (?) minút
          </label>
          <input
            type="number"
            className="form-control"
            id="durationStep"
            value={durationStep}
            min="1"
            onChange={(e) => setDurationStep(parseInt(e.target.value))}
            required
          />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">
            Generovať termíny
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTermAutomat;
