import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configApi from '../../config';

const UserTermsTable = ({ userId }) => {
    const [userTerms, setUserTerms] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc'); // Default sort order: newest to oldest

    useEffect(() => {
        const fetchUserTerms = async () => {
            const token = localStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            try {
                const response = await axios.get(`${configApi.apiEndpoint}/api/ApplicationUsers/User/${userId}`, config);
                setUserTerms(response.data);
            } catch (error) {
                console.error('Failed to fetch user terms:', error);
            }
        };

        if (userId) {
            fetchUserTerms();
        }

    }, [userId]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleDateString('sk-SK', options).replace(',', '');
    };

    const handleSort = () => {
        if (sortOrder === 'asc') {
            setSortOrder('desc');
        } else {
            setSortOrder('asc');
        }
    };

    const sortedTerms = [...userTerms].sort((a, b) => {
        if (sortOrder === 'asc') {
            return new Date(a.date) - new Date(b.date);
        }
        return new Date(b.date) - new Date(a.date);
    });

    return (
        <div className='card-custom'>
            <div className='card-custom-header'><h2>Moje termíny ({userTerms.length})</h2></div>
            <div>
                {sortedTerms.length === 0 ? <p className='p-0'>Zatiaľ nemáte rezervované žiadne termíny.</p> :
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Typ</th>
                                <th>
                                    <button onClick={handleSort} className="btn fw-bold p-0">
                                        Dátum {sortOrder === 'asc' ? '(najstaršie)' : '(najnovšie)'}
                                    </button>
                                </th>
                                <th>Cena</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedTerms.map(term => (
                                <tr key={term.id}>
                                    <td>{term.termType?.name} ({term.termType?.duration}min)</td>
                                    <td>{formatDate(term.date)}</td>
                                    <td>{term.termType?.price} €</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
            </div>
        </div>
    );
};

export default UserTermsTable;
