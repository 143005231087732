import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configApi from '../../config';

const TopCustomers = () => {
    const [customers, setCustomers] = useState([]);
    const token = localStorage.getItem("token");
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${configApi.apiEndpoint}/api/Stats/TopCustomers`,
                    config
                );
                setCustomers(response.data);
            } catch (error) {
                console.error('Error fetching top customers:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="card-custom">
            <div className="card-custom-header">
                <h5>Top zákazníci</h5>
            </div>
            {customers.length > 0 ? (
                <ul className="poptermtypes">
                    {customers.map((customer, index) => (
                        <li key={index}>
                            {customer.fullName}: {customer.count} termínov
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Nie sú dostupné žiadne údaje o zákazníkoch.</p>
            )}
        </div>
    );
};

export default TopCustomers;
