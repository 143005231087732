import React, { useEffect, useState } from "react";
import axios from "axios";
import configApi from "../../config";

const PatientsTable = () => {
  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await axios.get(
          `${configApi.apiEndpoint}/api/Patients`,
          config
        );
        setPatients(response.data);
        setLoading(false); // Set loading state to false on error as well
      } catch (error) {
        console.error("Failed to fetch patients:", error);
        setLoading(false); // Set loading state to false on error as well
      }
    };

    fetchPatients();
  });

  const handleCreate = () => {
    setSelectedPatient({});
  };

  const handleEdit = (patient) => {
    // Prepare the patient data for editing
    const patientForEditing = { ...patient };

    // Format the date in "yyyy-MM-dd" format
    if (patientForEditing.dateOfBirth) {
      const date = new Date(patientForEditing.dateOfBirth);
      patientForEditing.dateOfBirth = date.toISOString().slice(0, 10);
    }

    setSelectedPatient(patientForEditing);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectedPatient) {
      const patient = {
        id: selectedPatient.id,
        firstName: selectedPatient.firstName,
        lastName: selectedPatient.lastName,
        dateOfBirth: selectedPatient.dateOfBirth,
        phoneNumber: selectedPatient.phoneNumber,
        email: selectedPatient.email,
        address: selectedPatient.address,
      };

      const date = new Date(patient.dateOfBirth);
      patient.dateOfBirth = date.toISOString();

      if (patient.id) {
        handleEditPatient(patient);
      } else {
        handleAddPatient(patient);
      }
    }
  };

  const handleEditPatient = async (patient) => {
    try {
      await axios.put(
        `${configApi.apiEndpoint}/api/Patients/${patient.id}`,
        patient,
        config
      );
      const updatedPatients = patients.map((p) =>
        p.id === patient.id ? patient : p
      );
      setPatients(updatedPatients);
      setMessage("Pacient aktualizovaný.");
      setMessageType("success");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    } catch (error) {
      setMessage("Nepodarilo sa aktualizovať pacienta");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    }
  };

  const handleDeletePatient = async (patientId) => {
    try {
      await axios.delete(
        `${configApi.apiEndpoint}/api/Patients/${patientId}`,
        config
      );
      const remainingPatients = patients.filter(
        (patient) => patient.id !== patientId
      );
      setPatients(remainingPatients);
      setMessage("Pacient vymazaný.");
      setMessageType("success");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    } catch (error) {
      setMessage("Nepodarilo sa vymazať pacienta.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    }
  };

  const handleAddPatient = async (patient) => {
    try {
      const response = await axios.post(
        `${configApi.apiEndpoint}/api/Patients`,
        patient,
        config
      );
      setPatients([...patients, response.data]);
      setMessage("Pacient vytvorený.");
      setMessageType("success");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    } catch (error) {
      setMessage("Nepodarilo sa vytvoriť pacienta.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    }
  };

  const handleCloseForm = () => {
    setSelectedPatient(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedPatient((prevPatient) => ({ ...prevPatient, [name]: value }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const displayedPatients = patients.filter(
    (patient) =>
      (patient.firstName?.toLowerCase()?.includes(search.toLowerCase()) ??
        false) ||
      (patient.lastName?.toLowerCase()?.includes(search.toLowerCase()) ??
        false) ||
      (patient.phoneNumber?.includes(search) ?? false) ||
      (patient.email?.toLowerCase()?.includes(search.toLowerCase()) ?? false)
  );

  if (loading) {
    return <div>Loading...</div>; // Show loading message
  }

  return (
    <div className="card-custom">
      <h2>Pacienti</h2>
      <div className="d-flex justify-content-end gap-3 align-items-center">
        <button className="btn btn-primary" onClick={handleCreate}>
          Vytvoriť nového pacienta
        </button>
        <input
          type="search"
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          className="form-control w-25"
        />
      </div>
      {message && (
        <div
          className={`alert ${messageType === "success" ? "alert-success" : "alert-danger"
            }`}
        >
          {message}
        </div>
      )}
      {selectedPatient && (
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-title">
              {selectedPatient.id
                ? "Editovanie pacienta"
                : "Vytvorenie nového pacienta"}
            </h5>
            <button
              className="btn btn-outline-secondary rounded-circle btn-sm"
              onClick={handleCloseForm}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Meno</label>
                  <input
                    type="text"
                    name="firstName"
                    value={selectedPatient.firstName || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Priezvisko</label>
                  <input
                    type="text"
                    name="lastName"
                    value={selectedPatient.lastName || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Dátum narodenia</label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    value={selectedPatient.dateOfBirth || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Telefónne číslo</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={selectedPatient.phoneNumber || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={selectedPatient.email || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Adresa</label>
                  <input
                    type="text"
                    name="address"
                    value={selectedPatient.address || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <button className="btn btn-primary">
                {selectedPatient.id
                  ? "Aktualizovať pacienta"
                  : "Vytvoriť pacienta"}
              </button>
            </form>
          </div>
        </div>
      )}
      <table className="table table-striped mt-2">
        <thead>
          <tr>
            <th>Meno</th>
            <th>Dátum narodenia</th>
            <th>Telefónne číslo</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {displayedPatients.map((patient) => (
            <tr key={patient.id}>
              <td>
                {patient.firstName} {patient.lastName}
              </td>
              <td>
                {new Date(patient.dateOfBirth).toLocaleDateString("sk-SK")}
              </td>
              <td>{patient.phoneNumber}</td>
              <td>{patient.email}</td>
              <td className="">
                <div className="d-flex justify-content-end gap-1"><a
                  href={`/patient/${patient.id}`}
                  className="btn btn-primary"
                >
                  <i className="bi bi-eye-fill"></i>
                </a>
                  <button
                    className="btn btn-sm btn-warning mr-2"
                    onClick={() => handleEdit(patient)}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => handleDeletePatient(patient.id)}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PatientsTable;
