import React, { Component } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSidebar } from './SidebarContext';

class NavMenuBase extends Component {
  navigation = [{ name: "Rezervovať", href: "/reservations" }];

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLogin: !!localStorage.getItem("token"),
      username: localStorage.getItem("username") || "",
    };
  }

  checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (tokenExpiration && new Date().getTime() > tokenExpiration) {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("tokenExpiration");
      this.setState({ isLogin: false, username: "" });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.checkTokenExpiration();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
    }
  }

  handleLinkClick = () => {
    this.setState({ isOpen: false });
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { location, toggleSidebar } = this.props;
    const { isOpen, isLogin, username } = this.state;
    return (
      <header>
        <nav ref={this.setWrapperRef}>
          <button className="sidebar-toggler" type="button" onClick={toggleSidebar}>
            &#9776;
          </button>
          <Link to="/" className="brand-logo">
            <img src="/img/matalogo_black.png" alt="Logo" className="logo" />
            <span className="logotext">MK Masérsky salón</span>
          </Link>
          <button className="navbar-toggler" type="button" onClick={this.toggleMenu}>
            &#8942;
          </button>
          <div className={`nav-links ${this.state.isOpen ? "open" : ""}`}>
            <ul>
              {this.navigation.map((item, index) => (
                <li key={index} onClick={this.handleLinkClick}>
                  <Link
                    className={location.pathname === item.href ? "active" : ""}
                    to={item.href}
                    onClick={this.handleLinkClick}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="user-area">
              {isLogin ? (
                <>
                  <li onClick={this.handleLinkClick}><Link to="/user">{username}</Link></li>
                  <li onClick={this.handleLinkClick}><Link to="/logout">Odhlásiť sa</Link></li>
                </>
              ) : (
                <>
                  <li onClick={this.handleLinkClick}><Link to="/register">Registrovať</Link></li>
                  <li onClick={this.handleLinkClick}><Link to="/login">Prihlásiť</Link></li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </header>

    );
  }
}

// Functional component wrapper
const NavMenu = (props) => {
  const location = useLocation();
  const { toggleSidebar } = useSidebar();

  return <NavMenuBase {...props} location={location} toggleSidebar={toggleSidebar} />;
};

export default NavMenu;
