import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configApi from '../../config';

const PopularTermTypes = () => {
  const [types, setTypes] = useState([]);
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    axios.get(`${configApi.apiEndpoint}/api/Stats/PopularTermTypes`, config)
      .then(response => setTypes(response.data))
      .catch(error => console.error('There was an error fetching the popular term types:', error));
  }, []);

  return (
    <div className='sum-card'>
      <h4>Populárne typy termínov</h4>
      <ul className='poptermtypes'>
        {types.map((type, index) => (
          <li key={index}>
            {type.termTypeName ? type.termTypeName : "Bez kategórie"}: {type.count}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularTermTypes;
