// MainStatsComponent.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import configApi from "../../config";
import TermStats from "./TermStats";
import PatientStats from "./PatientStats";
import OccupiedTermsChart from "./OccupiedTermsChart";
import TermTypePieChart from './TermTypePieChart';
import TermsOverTimeChart from './TermsOverTimeChart';
import TermsHeatmap from './TermsHeatmap';
import TopCustomers from './TopCustomers';
import TotalReservations from './TotalReservations';

const MainStatsComponent = () => {
  const [terms, setTerms] = useState([]);
  const [patients, setPatients] = useState([]);
  const [activeTab, setActiveTab] = useState("prehľad"); // Default active tab in Slovak

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    fetchTerms();
    fetchPatients();
  }, []);

  const fetchTerms = async () => {
    try {
      const response = await axios.get(
        `${configApi.apiEndpoint}/api/Terms`,
        config
      );
      setTerms(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPatients = async () => {
    try {
      const response = await axios.get(
        `${configApi.apiEndpoint}/api/Patients`,
        config
      );
      setPatients(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="home-container">
      <nav>
        <ul className="nav nav-tabs flex-wrap">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "prehľad" ? "active" : ""}`}
              onClick={() => handleTabClick("prehľad")}
            >
              Prehľad
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "štatistikyTermínov" ? "active" : ""}`}
              onClick={() => handleTabClick("štatistikyTermínov")}
            >
              Štatistiky termínov
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "štatistikyPacientov" ? "active" : ""}`}
              onClick={() => handleTabClick("štatistikyPacientov")}
            >
              Štatistiky pacientov
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "populárneTypyTermínov" ? "active" : ""}`}
              onClick={() => handleTabClick("populárneTypyTermínov")}
            >
              Populárne typy termínov
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "termínyVČase" ? "active" : ""}`}
              onClick={() => handleTabClick("termínyVČase")}
            >
              Termíny v čase
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "termínovýHeatmap" ? "active" : ""}`}
              onClick={() => handleTabClick("termínovýHeatmap")}
            >
              Termínový heatmap
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "topZákazníci" ? "active" : ""}`}
              onClick={() => handleTabClick("topZákazníci")}
            >
              Top zákazníci
            </button>
          </li>
        </ul>
      </nav>

      {activeTab === "prehľad" && (
        <div className="mt-4">
          <div className="row">
            {/* Summary Cards */}
            <div className="col-md-3 mb-4">
              <TotalReservations />
            </div>
            <div className="col-md-3 mb-4">
              <PatientStats />
            </div>
            <div className="col-md-6 mb-4">
              <TopCustomers />
            </div>
          </div>

          <div className="row">
            {/* Charts */}
            <div className="col-md-6 mb-4">
              <OccupiedTermsChart />
            </div>
            <div className="col-md-6 mb-4">
              <TermTypePieChart />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-4">
              <TermsOverTimeChart />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-4">
              <TermsHeatmap />
            </div>
          </div>
        </div>
      )}

      {activeTab === "štatistikyTermínov" && <TermStats terms={terms} />}
      {activeTab === "štatistikyPacientov" && <PatientStats />}
      {activeTab === "populárneTypyTermínov" && <TermTypePieChart />}
      {activeTab === "termínyVČase" && <TermsOverTimeChart />}
      {activeTab === "termínovýHeatmap" && <TermsHeatmap />}
      {activeTab === "topZákazníci" && <TopCustomers />}
    </div>
  );
};

export default MainStatsComponent;
