import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';

const NewestTerms = () => {
  const [terms, setTerms] = useState([]);
  const [hoveredTermId, setHoveredTermId] = useState(null);
  const [count, setCount] = useState(10); // State to track the count of terms to display

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await axios.get(`${config.apiEndpoint}/api/Terms/NewestUpdates/${count}`);
        setTerms(response.data);
      } catch (error) {
        console.error('Error fetching newest terms:', error);
      }
    };

    fetchTerms();
  }, [count]);

  const formatTermTime = (term) => {
    const startTime = new Date(term.date);
    const endTime = new Date(startTime.getTime() + (term.termType ? term.termType.duration * 60000 : 0));
    const formattedStartTime = startTime.toLocaleTimeString("sk-SK", { hour: '2-digit', minute: '2-digit', hour12: false });
    const formattedEndTime = endTime.toLocaleTimeString("sk-SK", { hour: '2-digit', minute: '2-digit', hour12: false });
    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const formatTime = (date) => {
    return new Date(date).toLocaleTimeString("sk-SK", { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const formatDate = (date) => {
    return `${new Date(date).getDate()}.${new Date(date).getMonth() + 1 < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth() + 1}.${new Date(date).getFullYear()}`;
  };

  const handleMouseEnter = (id) => setHoveredTermId(id);

  const handleMouseLeave = () => setHoveredTermId(null);

  const handleTermClick = (termId) => console.log(`Term clicked: ${termId}`);

  return (
    <div className="newest-terms">
      <div className="newest-terms-header">
        <h2>Najnovšie prihlásenia</h2>
        <div className="term-count-selector">
          <label htmlFor="countSelect">Počet:</label>
          <select id="countSelect" value={count} onChange={(e) => setCount(e.target.value)}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </div>
      </div>
      <ul className="nt-list">
        {terms.map((term) => (
          <li
            key={term.id}
            className={`${hoveredTermId === term.id ? 'hovered' : ''}`}
            onMouseEnter={() => handleMouseEnter(term.id)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleTermClick(term.id)}
          >
            <div className='date'>
              <span className='time'>
                {formatTermTime(term)}
              </span>
              <span>
                {formatDate(term.date)}
              </span>
            </div>
            <div className='details'>
              <span className='type'>{term.termType?.name}</span>
              <span className='user'>{term.firstName} {term.lastName}</span>
            </div>
            <span className='rightdetail'>
              {term.updatedByUser ? formatTime(term.updatedByUser) + ' ' + formatDate(term.updatedByUser) : 'N/A'}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewestTerms;
