import ApiAuthorzationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Home from "./components/Home";
import { TermList } from "./components/TermList";
import Reservations from "./components/reservations/Reservations";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Logout from "./components/auth/Logout";
import UserProfilePage from "./components/User/Profile";
import PatientDetail from "./components/Patients/PatientDetail";
import ClearTermByHashPage from "./components/reservations/ClearTermHashPage";
import WeekCalendar from "./components/BlockReservations/WeekCalendar";
import ForgotPassword from "./components/auth/ForgotPassword";


const AppRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/reservations",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/user",
    element: <UserProfilePage />,
  },
  {
    path: "/patient/:patientId",
    element: <PatientDetail />,
  },
  {
    path: "/calendar",
    element: <TermList />,
  },
  {
    path: "/blockreservations",
    element: <WeekCalendar />,
  },
  {
    path: "/cleartermbyhash",
    element: <ClearTermByHashPage />,
  },
  {
    path: "/fetch-data",
    requireAuth: true,
    element: <FetchData />,
  },
  ...ApiAuthorzationRoutes,
  {
    path: "/test",
    requireAuth: true,
    element: <Counter />,
  },
];

export default AppRoutes;
