import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(e.message);
            const response = await axios.post(`${config.apiEndpoint}/api/account/forgot-password`, { email });
            console.log(response);
            setMessage(response.data.message || 'If an account with that email exists, we have sent a link to reset your password.');
        } catch (error) {
            console.log(error);
            setError('Something went wrong. Please try again.');
        }
    };

    return (
        <div className="container py-3">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h1>Reset Password</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email Address</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">Send Reset Link</button>
                    </form>
                    {message && (
                        <div className="alert alert-info mt-3">{message}</div>
                    )}
                    {error && (
                        <div className="alert alert-danger mt-3">{error}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
