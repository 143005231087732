import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configApi from '../../config';

const ReservationsByMonth = () => {
    const [data, setData] = useState([]);
    const token = localStorage.getItem("token");
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    useEffect(() => {
        axios.get(`${configApi.apiEndpoint}/api/Stats/ReservationsByMonth`, config)
            .then(response => setData(response.data))
            .catch(error => { console.error('There was an error fetching the reservations by month:', error); console.log(error) });
    }, []);

    return (
        <div className='sum-card'>
            <h4>Počet rezervácii na mesiac</h4>
            <ul className='poptermtypes'>
                {data.map((item, index) => (
                    <li key={index}>{item.year} / {new Date(item.year, item.month - 1).toLocaleString('sk-SK', { month: 'long' })}: {item.count}</li>
                ))}
            </ul>
        </div>
    );

};

export default ReservationsByMonth;
