import React from 'react';
import Reservations from './reservations/Reservations'; // Adjust the path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
  return (
    <>
      <div className='home-container'>

        <Reservations />

      </div>
      <footer className="footer-container">
        <div className="footer-content">
          <div className="footer-section address">
            <h3>Adresa</h3>
            <address>
              <p>Polyfunkčný dom Vitamín Orava</p>
              <p>Jána Vojtaššáka 452</p>
              <p>Oravská Polhora 029 47</p>
              <p>Slovensko</p>
            </address>
          </div>
          <div className="footer-section contact">
            <h3>Kontakt</h3>
            <p>
              <a href="tel:+421904570479" aria-label="Zavolajte nám na +421 904 570 479">
                <FontAwesomeIcon icon={faPhone} /> +421 904 570 479
              </a>
            </p>
            <p>
              <a href="mailto:maserskysalonmk@gmail.com" aria-label="Pošlite nám email na maserskysalonmk@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} /> maserskysalonmk@gmail.com
              </a>
            </p>
            <p className='name'>Martina Kormanová</p>
            <div className="social-icons">
              <a
                href="https://facebook.com/Maserskysalonkm"
                target="_blank"
                rel="noopener noreferrer"
                className="icon"
                aria-label="Navštívte našu Facebook stránku"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://instagram.com/mk_masersky_salon/"
                target="_blank"
                rel="noopener noreferrer"
                className="icon"
                aria-label="Navštívte náš Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
          <div className="footer-section map">
            <h3>Mapa</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2896.978055056634!2d19.44549921220111!3d49.52075455374717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715cd0bc9a2476d%3A0x8ae528907cdccebc!2zTUsgbWFzw6lyc2t5IHNhbMOzbg!5e1!3m2!1ssk!2ssk!4v1729764292317!5m2!1ssk!2ssk"
              width="100%"
              height="200"
              allowFullScreen=""
              loading="lazy"
              title="Google Mapa"
            ></iframe>
          </div>
        </div>
      </footer>
    </>

  );
};

export default Home;
