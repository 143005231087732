// OccupiedTermsChart.js
import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import configApi from '../../config';

const OccupiedTermsChart = () => {
    const chartRef = useRef(null);
    const token = localStorage.getItem("token");
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    useEffect(() => {
        const fetchRevenueData = async () => {
            try {
                const response = await axios.get(
                    `${configApi.apiEndpoint}/api/Stats/RevenueOverTime`,
                    config
                );
                const data = response.data;
                createChart(data);
            } catch (error) {
                console.error('Chyba pri načítavaní údajov o príjmoch:', error);
            }
        };

        const createChart = (data) => {
            const ctx = chartRef.current.getContext('2d');
            const labels = data.map(item => `${item.year}-${item.month.toString().padStart(2, '0')}`);
            const revenues = data.map(item => item.revenue);

            new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        label: 'Mesačné príjmy (€)',
                        data: revenues,
                        fill: true,
                        backgroundColor: 'rgba(75,192,192,0.2)',
                        borderColor: '#4BC0C0',
                        tension: 0.1,
                        pointBackgroundColor: '#fff',
                        pointBorderColor: '#4BC0C0',
                        pointHoverBackgroundColor: '#4BC0C0',
                        pointHoverBorderColor: '#fff',
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Príjmy (€)'
                            }
                        },
                        x: {
                            title: {
                                display: true,
                                text: 'Mesiac'
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top'
                        },
                        title: {
                            display: true,
                            text: 'Mesačné príjmy'
                        }
                    }
                }
            });
        };

        fetchRevenueData();
    }, []);

    return (
        <div className="card-custom">
            <div className="card-custom-header">
                <h5>Mesačné príjmy</h5>
            </div>
            <div style={{ height: '300px' }}>
                <canvas ref={chartRef}></canvas>
            </div>
        </div>
    );
};

export default OccupiedTermsChart;
