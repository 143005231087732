// TermStats.js
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const TermStats = ({ terms }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (terms.length > 0) {
      createChart();
    }
  }, [terms]);

  const createChart = () => {
    const termsByMonth = {};
    terms.forEach((term) => {
      const date = new Date(term.date);
      const month = date.toLocaleString("sk-SK", {
        month: "long",
        year: "numeric",
      });

      if (!termsByMonth[month]) {
        termsByMonth[month] = {
          isOccupied: 0,
          notOccupied: 0,
        };
      }

      if (term.isOccupied) {
        termsByMonth[month].isOccupied++;
      } else {
        termsByMonth[month].notOccupied++;
      }
    });

    const months = Object.keys(termsByMonth);
    const isOccupiedData = months.map(
      (month) => termsByMonth[month].isOccupied
    );
    const notOccupiedData = months.map(
      (month) => termsByMonth[month].notOccupied
    );

    const chartData = {
      labels: months,
      datasets: [
        {
          label: "Obsadené termíny",
          data: isOccupiedData,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
        {
          label: "Voľné termíny",
          data: notOccupiedData,
          backgroundColor: "rgba(192, 75, 75, 0.6)",
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          precision: 0,
          title: {
            display: true,
            text: 'Počet termínov',
          },
        },
        x: {
          title: {
            display: true,
            text: 'Mesiac',
          },
        },
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Termíny podľa mesiaca',
        },
      },
    };

    const chartConfig = {
      type: "bar",
      data: chartData,
      options: chartOptions,
    };

    if (chartRef.current) {
      new Chart(chartRef.current, chartConfig);
    }
  };

  const totalTerms = terms.length;
  const occupiedTerms = terms.filter(term => term.isOccupied).length;
  const presentTerms = terms.filter(term => term.wasPresent).length;
  const totalRevenue = terms.reduce((total, term) => {
    if (term.wasPresent && term.termType) {
      return total + term.termType.price;
    }
    return total;
  }, 0);
  const averageDuration = (terms.reduce((total, term) => total + term.duration, 0) / totalTerms).toFixed(2);
  const noShowRate = occupiedTerms > 0 ? (((occupiedTerms - presentTerms) / occupiedTerms) * 100).toFixed(2) : 0;

  return (
    <div className="card-custom">
      <div className="card-custom-header">
        <h5>Štatistiky termínov</h5>
      </div>
      <div className="term-stats-content">
        <p>Celkový počet termínov: <strong>{totalTerms}</strong></p>
        <p>Obsadené termíny: <strong>{occupiedTerms}</strong></p>
        <p>Prítomní na termíne: <strong>{presentTerms}</strong></p>
        <p>Neprítomní na termíne: <strong>{occupiedTerms - presentTerms}</strong></p>
        <p>Miera neprítomnosti: <strong>{noShowRate}%</strong></p>
        <p>Priemerná dĺžka termínu: <strong>{averageDuration} minút</strong></p>
        <p>Celkové príjmy: <strong>€{totalRevenue}</strong></p>
      </div>
      <div style={{ height: '400px' }}>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default TermStats;
