import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import ResetPassword from './ResetPssword';

const UserProfileSettings = ({ user }) => {
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [email, setEmail] = useState(user.email || '');
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber || '');
  const [address, setAddress] = useState(user.address || '');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isAdmin, setIsAdmin] = useState((user?.roles && user.roles.includes('Admin')) ? user.roles[0] : null || '');
  const [dateOfBirth, setDateOfBirth] = useState(user.dateOfBirth ? new Date(user.dateOfBirth).toISOString().split('T')[0] : '');
  const [sex, setSex] = useState(user.sex || '');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        dateOfBirth: dateOfBirth ? new Date(dateOfBirth).toISOString() : null,
        sex: sex,
        address: address,
        isAdmin: isAdmin ? isAdmin : false
      };

      const response = await axios.post(`${config.apiEndpoint}/api/account/settings`, data, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setSuccessMessage('Profilove údaje aktualizované.');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      setErrorMessage('Nepodarilo sa aktualizovať, skús to znova.');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  return (
    <div>
      <div className='card-custom margin-bottom-1'>
        <h2>Základne údaje</h2>
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit} className='mb-3'>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">Meno</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Meno"
                  autoComplete="off"

                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">Priezvisko</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Priezvisko"
                  autoComplete="off"

                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  autoComplete="off"
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">Telefónne číslo</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Telefónne číslo"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">Adresa</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Adresa"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="dateOfBirth" className="form-label">Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  id="dateOfBirth"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  placeholder="Date of Birth"
                  required />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-sm-6">
              <div className="mb-3">
                <label className="form-label">Pohlavie</label>
                <div className='d-flex gap-3'>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sex"
                      id="male"
                      value="Male"
                      checked={sex === "Male"}
                      onChange={(e) => setSex(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="male">
                      Muž
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sex"
                      id="female"
                      value="Female"
                      checked={sex === "Female"}
                      onChange={(e) => setSex(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="female">
                      Žena
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {user?.roles && user.roles.includes('Admin') ? (
              <div className='col-sm-6'>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="adminCheck"
                    checked={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="adminCheck">Admin</label>
                </div>
              </div>
            ) : null}
          </div>

          <button type="submit" className="btn btn-primary">Aktualizovať</button>
        </form>
      </div>
      <div>
        <ResetPassword />
      </div>
    </div >
  );
};

export default UserProfileSettings;
