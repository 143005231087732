import React, { useEffect, useState } from "react";
import axios from "axios";
import configApi from "../../config";

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [patients, setPatients] = useState([]);

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${configApi.apiEndpoint}/api/ApplicationUsers`,
          config
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axios.get(
          `${configApi.apiEndpoint}/api/ApplicationUsers/roles`,
          config
        );
        setRoles(response.data);
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      }
    };

    const fetchPatients = async () => {
      try {
        const response = await axios.get(configApi.apiEndpoint + "/api/patients", config);
        setPatients(response.data);
      } catch (error) {
        console.error("Failed to fetch patients:", error);
      }
    };
    fetchUsers();
    fetchRoles();
    fetchPatients();
  }, []);

  const handleEdit = (user) => {
    const userForEditing = { ...user };

    if (userForEditing.dateOfBirth) {
      const date = new Date(userForEditing.dateOfBirth);
      userForEditing.dateOfBirth = date.toISOString().slice(0, 10);
    }

    setSelectedUser(userForEditing);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedUser) {
      const user = {
        id: selectedUser.id,
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        userName: selectedUser.userName,
        dateOfBirth: selectedUser.dateOfBirth,
        email: selectedUser.email,
        address: selectedUser.address,
        roles: selectedUser.roles,
        phoneNumber: selectedUser.phoneNumber,
        patient: selectedUser.patient,
      };

      const date = new Date(user.dateOfBirth);
      user.dateOfBirth = date.toISOString();

      if (user.roles && user.roles.length === 0) {
        user.roles = null;
      }

      if (user.id) {
        await handleEditUser(user);
      }
    }
  };

  const handleEditUser = async (user) => {
    try {
      console.log(user);
      const updatedUser = {
        ...user,
        patientId: user.patient?.id,
      };

      console.log(updatedUser);
      const response = await axios.put(
        `${configApi.apiEndpoint}/api/ApplicationUsers/${user.id}`,
        updatedUser,
        config
      );

      const updatedUsers = users.map((u) => (u.id === user.id ? response.data : u));
      setUsers(updatedUsers);
      setMessage("Používateľ úspešne aktualizovaný.");
      setMessageType("success");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    } catch (error) {
      setMessage("Aktualizácia používateľa zlyhala.");
      setMessageType("error");
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 3000);
    }
  };

  const handleDeleteUser = async (userId) => {
    const confirmed = window.confirm('Ste si istý, že chcete odstrániť tohto používateľa?');
    if (confirmed) {
      try {
        await axios.delete(`${configApi.apiEndpoint}/api/ApplicationUsers/${userId}`, config);
        const remainingUsers = users.filter((user) => user.id !== userId);
        setUsers(remainingUsers);
        setMessage('Používateľ odstránený.');
        setMessageType('success');
        setTimeout(() => {
          setMessage(null);
          setMessageType(null);
        }, 3000);
      } catch (error) {
        setMessage('Odstránenie používateľa zlyhalo.');
        setMessageType('error');
        setTimeout(() => {
          setMessage(null);
          setMessageType(null);
        }, 3000);
      }
    }
  };

  const handleCloseForm = () => {
    setSelectedUser(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "patientId") {
      // Assuming you want to set patientId to null if no patient is selected (empty string)
      setSelectedUser((prevUser) => ({ ...prevUser, patient: { id: value || null } }));
    } else {
      setSelectedUser((prevUser) => ({ ...prevUser, [name]: value }));
    }
  };


  const handleRoleChange = (event) => {
    const { value } = event.target;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      roles: value ? [value] : [],
    }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    setSelectedUser((prevUser) => ({ ...prevUser, phoneNumber: value }));
  };

  const displayedUsers = users.filter(
    (user) =>
      (user.firstName?.toLowerCase()?.includes(search.toLowerCase()) ?? false) ||
      (user.lastName?.toLowerCase()?.includes(search.toLowerCase()) ?? false) ||
      (user.email?.toLowerCase()?.includes(search.toLowerCase()) ?? false)
  );

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <input
          type="search"
          placeholder="Hľadať"
          value={search}
          onChange={handleSearch}
          className="form-control w-25 ms-auto"
        />
      </div>
      {message && (
        <div
          className={`alert ${messageType === "success" ? "alert-success" : "alert-danger"
            }`}
        >
          {message}
        </div>
      )}
      {selectedUser && (
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-title">
              {selectedUser.id ? "Upraviť používateľa" : "Vytvoriť používateľa"}
            </h5>
            <button
              className="btn btn-outline-secondary rounded-circle btn-sm"
              onClick={handleCloseForm}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Meno</label>
                  <input
                    type="text"
                    name="firstName"
                    value={selectedUser.firstName || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Priezvisko</label>
                  <input
                    type="text"
                    name="lastName"
                    value={selectedUser.lastName || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Dátum narodenia</label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    value={selectedUser.dateOfBirth || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={selectedUser.email || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Adresa</label>
                  <input
                    type="text"
                    name="address"
                    value={selectedUser.address || ""}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Telefónne číslo</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={selectedUser.phoneNumber || ""}
                    onChange={handlePhoneNumberChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Rola</label>
                  <select
                    name="role"
                    value={selectedUser.roles != null ? selectedUser.roles[0] : ""}
                    onChange={handleRoleChange}
                    className="form-select"
                  >
                    <option value="">Vyber rolu</option>
                    {roles.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 md-3">
                  <label className="form-label">Pacient</label>
                  <select
                    name="patientId"
                    value={selectedUser.patient?.id || ""}
                    onChange={handleChange}
                    className="form-select"
                  >
                    <option value="">Vyber pacienta</option>
                    {patients.map((patient) => (
                      <option key={patient.id} value={patient.id}>
                        {patient.name} {patient.firstName} {patient.lastName} ({new Date(patient.dateOfBirth).toLocaleDateString('sk-SK')})
                      </option>
                    ))}
                  </select>
                </div>

              </div>
              <button className="btn btn-primary">
                {selectedUser.id ? "Aktualizovať používateľa" : "Vytvoriť používateľa"}
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
        <table className="table table-striped mt-2 table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Meno</th>
              <th>Priezvisko</th>
              <th>Používateľské meno</th>
              <th>Dátum narodenia</th>
              <th>Email</th>
              <th>Adresa</th>
              <th>Pacient</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {displayedUsers.map((user) => (
              <tr key={user.id} style={{ cursor: "pointer" }}>
                <td>
                  {user.roles.includes("Admin") && (
                    <span style={{ color: "green" }}>&bull;</span>
                  )}
                </td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.userName}</td>
                <td>
                  {user.dateOfBirth
                    ? new Date(user.dateOfBirth).toLocaleDateString("en-US")
                    : null}
                </td>
                <td>{user.email}</td>
                <td>{user.address}</td>
                <td>{user.patient ? <i className="bi bi-check2"></i> : <i className="bi bi-x"></i>}</td>
                <td className="">
                  <div className="d-flex flex-grow-1 align-items-stretch justify-content-end gap-1">
                    <button
                      className="btn btn-sm btn-warning d-flex align-items-center justify-content-center flex-grow-1"
                      onClick={() => handleEdit(user)}
                      style={{ height: '100%' }}
                    >
                      <i className="bi bi-pencil-fill"></i>
                    </button>
                    <button
                      className="btn btn-sm btn-danger d-flex align-items-center justify-content-center flex-grow-1"
                      onClick={() => handleDeleteUser(user.id)}
                      style={{ height: '100%' }}
                    >
                      <i className="bi bi-trash-fill"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default UsersTable;
