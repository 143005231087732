// TermsHeatmap.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import configApi from '../../config';
import { Tooltip as ReactTooltip } from 'react-tooltip'; // Updated import

const TermsHeatmap = () => {
    const [values, setValues] = useState([]);
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    useEffect(() => {
        const fetchTermCounts = async () => {
            try {
                const response = await axios.get(
                    `${configApi.apiEndpoint}/api/Terms/TermCountsByDate`,
                    config
                );
                const data = response.data.map((item) => ({
                    date: item.date.split('T')[0],
                    count: item.count,
                }));
                setValues(data);
            } catch (error) {
                console.error('Chyba pri načítavaní počtu termínov:', error);
            }
        };

        fetchTermCounts();
    }, []);

    return (
        <div className="card-custom">
            <div className="card-custom-header">
                <h5>Termínový heatmap</h5>
            </div>
            <div>
                <CalendarHeatmap
                    startDate={new Date(
                        new Date().setFullYear(new Date().getFullYear() - 1)
                    )}
                    endDate={new Date()}
                    values={values}
                    classForValue={(value) => {
                        if (!value || value.count === 0) {
                            return 'color-empty';
                        }
                        return `color-github-${Math.min(value.count, 4)}`;
                    }}
                    tooltipDataAttrs={(value) => {
                        if (!value || !value.date) {
                            return null;
                        }
                        return {
                            'data-tooltip-id': 'heatmap-tooltip',
                            'data-tooltip-content': `${value.date}: ${value.count} termínov`,
                        };
                    }}
                    showWeekdayLabels={true}
                />
                <ReactTooltip id="heatmap-tooltip" />
            </div>
        </div>
    );
};

export default TermsHeatmap;
